import { createSignal, Show, Switch as SolidSwitch, Match, JSX, } from "solid-js";
import { createStore, } from "solid-js/store"; 
import { MenuIcon } from "../icons"
import { CardWithMinimize } from '../AppMenuBar/CardList'

import { ShowHeading } from '../SmallComponents/SmallHeader'
import { RatingComment, RatingDetails } from '../SmallComponents/SmallShowRatingComment';

import {
    Menu, MenuItem, Switch, Button, Typography, Grid,
    Card, Stack, Divider, MenuList, FormControlLabel,
    TextField, DialogActions, Box
} from "@suid/material";
import { getText } from '../SmallComponents/Translate'

const ShowMenu = () => {
    const [anchorEl, setAnchorEl] = createSignal<HTMLElement | null>(null);
    const [selectedMenuIndex, setSelectedMenuIndex] = createSignal<number>(1);

    const openMenu = () => !!anchorEl();

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenuListItem = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        openMenu()
    };

    const menuItems = [

        { text: "Local action menu 1", switch: true, checked: true, },

        { text: "Local action menu 2", disabled: true, },

    ];

    return (
        <>
            <Button size="small" color="primary" variant="text" onClick={handleClickMenuListItem}>  <MenuIcon /> </Button>
            {openMenu() && (

                <Menu anchorEl={anchorEl()} open={openMenu()} onClose={handleMenuClose}>
                    <MenuList >

                        {menuItems.map((item, index) => (
                            <MenuItem value={index} disabled={item.disabled}>
                                {item.switch && <Switch id="autoconfirmedEnabled" defaultChecked={item.checked} />}
                                {item.text}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

            )}
        </>

    );
}


interface CustomerWFFeedbackComponentProps {
    header: string;
    type: string; // "contract" | "proposal" | "interview" | "matching" | "drop" | "greylist";
}


const CustomerWFFeedbackComponent = (props: CustomerWFFeedbackComponentProps) => {
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [state, setState] = createStore({
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const [textMessage, setTextMessage] = createSignal("");

    const formatNumberBasedOnLocale = (numberString: string): string => {
        const number = Number(numberString.replace(/,/g, ''));
        const formatter = new Intl.NumberFormat(navigator.language);
        return formatter.format(number);
    }
    const txtSendContract = getText("workflowpage", "sendcontract");
    const txtSendPositiveFeedback = getText("workflowpage", "sendpositivefeedback");
    const txtSendNegativeFeedback = getText("workflowpage", "sendnegativefeedback");
    const txtSend = getText("workflowpage", "send");
    const txtSendPositiveFeedbackTo = getText("workflowpage", "sendpositivefeedbackto",[{name: "Name", value: props.header}]);
    const txtSendNegativeFeedbackTo = getText("workflowpage", "sendnegativefeedbackto",[{name: "Name", value: props.header}]);
    const txtInternalMessage = getText("workflowpage", "internalmessage");
    const txtExternalMessage = getText("workflowpage", "externalmessage");
    const txtSaveDraft = getText("workflowpage", "savedraft");
    const txtOptions = getText("workflowpage", "options");
    const txtGreylistCandidate = getText("workflowpage", "greylistcandidate");
    const txtRateCandidate = getText("workflowpage", "ratecandidate");
    const txtTechnicalCompetence = getText("workflowpage", "technicalcompetence");
    const txtSocialCompetence = getText("workflowpage", "socialcompetence");
    const txtWorkTogetherAgain = getText("workflowpage", "worktogetheragain");

    const getButtonActionText = (type: string) => {
        if (type === "positiveFeedback")
            return txtSendPositiveFeedback()
        else if (type === "negativeFeedback")
            return txtSendNegativeFeedback()
        else
            return txtSend()
    }

    return (

        <>
            {/* ********************** */}
            {/* Show button in wf bar */}
            {/* ********************** */}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <Box sx={{ margin: 2, width: 1000, bgcolor: 'background.default'[200] }}>
                    <Card>

                        <Stack direction="column" alignItems="top" justifyContent="space-between" sx={{ display: 'flex' }} >
                            {/* Addition navigation options  */}
                            <Box sx={{ margin: 1, display: 'flex', }} >

                                <ShowMenu />
                                <Show when={props.type === "positiveFeedback"}
                                    fallback={
                                        <ShowHeading >{txtSendNegativeFeedbackTo()}</ShowHeading>
                                    }
                                >
                                    <ShowHeading>{txtSendPositiveFeedbackTo()}</ShowHeading>
                                </Show>
                            </Box>
                        </Stack>

                        <Divider />

                        <Card sx={{ margin: 1, display: 'flex' }}>

                            <Grid container spacing={2}>

                                <Grid item xs={12} md={6}  >

                                    {/* Contract Period*/}
                                    <CardWithMinimize header={txtInternalMessage() as string} type="list" >

                                        <TextField
                                            required
                                            fullWidth={true}

                                            id="textarea-input"


                                            multiline
                                            rows={6}

                                            autoComplete="text"
                                            variant="outlined"
                                            size="small"
                                        />
                                    </CardWithMinimize>

                                    <Show when={props.type == "negativeFeedback"} >
                                        <CardWithMinimize header={txtOptions() as string} type="list" defaultOpen={true}>
                                            <Stack direction="column" spacing={2} alignItems="center" margin={2}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch id="autoconfirmedEnabled" />
                                                    }
                                                    label={txtGreylistCandidate()}
                                                    sx={{ px: 2 }}
                                                />
                                            </Stack>
                                        </CardWithMinimize>
                                    </Show>
  
                                    <CardWithMinimize header={txtRateCandidate() as string} type="list" defaultOpen={true}>
                                        <RatingDetails rating={3.4} >{txtTechnicalCompetence()}</RatingDetails>
                                        <RatingDetails rating={5}>{txtSocialCompetence()}</RatingDetails> 
                                        <RatingDetails rating={2} showDivider={false} >{txtWorkTogetherAgain()}</RatingDetails>
                                    </CardWithMinimize>
                                </Grid>

                                {/* ***************************** */}
                                {/* Preview message section       */}
                                {/* ***************************** */}

                                <Grid item xs={12} md={6} >

                                    <Card sx={{ backgroundColor: 'background.default'[50], flexGrow: 1, margin: 1 }} >
                                        <Stack direction="column" margin={1} spacing={2}>

                                            {/* Message*/}
                                            <CardWithMinimize header={txtExternalMessage() as string} type="list" >
                                                <TextField
                                                    required
                                                    fullWidth={true}
                                                    sx={{ width: "100%" }}
                                                    id="textarea-input"

                                                    multiline
                                                    rows={4}

                                                    autoComplete="text"
                                                    variant="outlined"
                                                    size="small"
                                                    value={textMessage()}

                                                    onChange={(event) => {
                                                        setTextMessage(event.target.value);

                                                    }}
                                                />
                                            </CardWithMinimize>


                                            {/*  Typography color="text.secondary" innerHTML={previewTextMessage().replace(/\n/g, '<br>')} > */}
                                            <Show when={props.type === "positiveFeedback"}
                                                fallback={
                                                    <>  <Typography color="text.secondary" p={0.5} >
                                                        Hi Michael,<br />

                                                        Thanks for taking your time to discuss the potentiel assignment. We are sorry to inform you that we have offered the assignment to another candidate.  <Show
                                                            when={textMessage()}
                                                            fallback={
                                                                <>
                                                                    <br /><br />
                                                                </>
                                                            }
                                                        >
                                                            <br />
                                                            {textMessage()}
                                                            <br /><br />
                                                        </Show>

                                                        <br />
                                                        We hope we can offer your a position next time.
                                                        <br />
                                                        <b>Best regards,</b><br />
                                                        Lion Messia
                                                    </Typography>
                                                    </>
                                                }
                                            >
                                                <Typography color="text.secondary" p={0.5} >

                                                    Hi Michael,<br />

                                                    Thanks for taking your time to discuss the potentiel assignment. We are very pleased with the interview and will come back shortly with next step.

                                                    Please confirm if you are ready to proceed the process. To speed up process, please review our standard contract including our abriations.
                                                    <Show
                                                        when={textMessage()}
                                                        fallback={
                                                            <>
                                                                <br /><br />
                                                            </>
                                                        }
                                                    >
                                                        <br />
                                                        {textMessage()}
                                                        <br /><br />
                                                    </Show>

                                                    <br />
                                                    Look forward to hearing from you.
                                                    <br />
                                                    <b>Best regards,</b><br />
                                                    Lion Messia
                                                </Typography>
                                            </Show>
                                        </Stack>

                                    </Card>
                                </Grid>
                            </Grid>

                        </Card>

                        <DialogActions>

                            <Button variant="outlined" >
                            {txtSaveDraft()}
                            </Button>
                            <Button variant="contained">
                                {getButtonActionText(props.type)}
                            </Button>

                        </DialogActions>
                    </Card>
                </Box>
            </Box>
        </>
    );
}

export { CustomerWFFeedbackComponent }