import { createSignal, Switch as SolidSwitch, Match } from "solid-js";
import { CustomerTemplatePage } from './CustomerTemplatePage'
 
import { CustomerWFTemplatePage } from '../components/Workflow/CustomerWFTemplatePage'
import { CustomerWFInterviewComponent } from '../components/Workflow/CustomerWfInterviewComponent'
import { CustomerWFFeedbackComponent } from '../components/Workflow/CustomerWfFeedbackComponent'
import { CustomerWFContractSignComponent } from '../components/Workflow/CustomerWfContractSignComponent'
import { CustomerWFContractTerminateComponent } from '../components/Workflow/CustomerWfContractTerminateComponent'
import { getText } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'

const ShowCustomerWorkflowPage = () => {

    const navigate = useNavigate();

    if (!isLoggedIn()) {
        navigate('/login');
        return;
    }

    const [selectedButton, setSelectedButton] = createSignal<string>('positiveFeedback');
    const txtHeader = getText("workflowpage", "header")
    const txtError = getText("workflowpage", "error")
    return (
        <>
            <SolidSwitch fallback={<div>{txtError()}</div>}>

                <Match when={selectedButton() === "interview"}>
                    <CustomerTemplatePage
                        type="customer"
                        leftPaneComponent={null}
                        mainComponent={<CustomerWFInterviewComponent type={selectedButton()} header={"Tim Timberlake "} />}
                        buttonComponent={null} >{txtHeader()}</CustomerTemplatePage>
                </Match>
                <Match when={selectedButton() === "positiveFeedback" || selectedButton() === "negativeFeedback"}>
                    <CustomerTemplatePage
                        type="customer"
                        leftPaneComponent={null}
                        mainComponent={<CustomerWFFeedbackComponent type={selectedButton()} header={"Tim Timberlake "} />}
                        buttonComponent={null}  >{txtHeader()}</CustomerTemplatePage>
                </Match>
                <Match when={selectedButton() === "contract" || selectedButton() === "extendContract"}>
                    <CustomerTemplatePage
                        type="customer"
                        leftPaneComponent={null}
                        mainComponent={<CustomerWFTemplatePage type={selectedButton()} header={"Tim Timberlake "} />}
                        buttonComponent={null}  >{txtHeader()}</CustomerTemplatePage>
                </Match>
                <Match when={selectedButton() === "contract" || selectedButton() === "signContract"}>
                    <CustomerTemplatePage
                        type="customer"
                        leftPaneComponent={null}
                        mainComponent={<CustomerWFContractSignComponent type={selectedButton()} header={"Tim Timberlake "} />}
                        buttonComponent={null}  >{txtHeader()}</CustomerTemplatePage>
                </Match>
                <Match when={selectedButton() === "terminateContract"}>
                    <CustomerTemplatePage
                        type="customer"
                        leftPaneComponent={null}
                        mainComponent={<CustomerWFContractTerminateComponent type={selectedButton()} header={"Tim Timberlake "} />}
                        buttonComponent={null}  >{txtHeader()}</CustomerTemplatePage>
                </Match>
                <Match when={selectedButton() === "proposal"}>
                    <CustomerTemplatePage
                        type="customer"
                        leftPaneComponent={null}
                        mainComponent={<CustomerWFTemplatePage type={selectedButton()} header={"Tim Timberlake "} />}
                        buttonComponent={null}  >{txtHeader()}</CustomerTemplatePage>
                </Match>
            </SolidSwitch>
        </>
    );
}
export { ShowCustomerWorkflowPage }